import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'

import DriverService from 'services/DriverService'

export const initialState = {
    driverList: {
        data: [],
        isLoading: false,
        error: false,
    },

    driverDetail: {
        data: [],
        isLoading: false,
        error: false,
    },

    driverAdd: {
        data: [],
        isLoading: false,
        success: null,
        error: false,
    },

    driverUpdate: {
        data: [],
        isLoading: false,
        success: null,
        error: false,
    },

    addressUpdate: {
        data: [],
        isLoading: false,
        success: null,
        error: false,
    },

    vehicleUpdate: {
        data: [],
        isLoading: false,
        success: null,
        error: false,
    },

    rfidList: {
        data: [],
        isLoading: false,
        error: false,
    },

    addBalance: {
        data: [],
        isLoading: false,
        error: false,
    },
    addAddresses: {
        data: [],
        isLoading: false,
        error: false,
    },
    addVehicles: {
        data: [],
        isLoading: false,
        error: false,
    },
    addRfid: {
        data: [],
        isLoading: false,
        error: false,
    },
    deleteDriver: {
        data: [],
        isLoading: false,
        error: false,
    },
    deleteRfid: {
        data: [],
        isLoading: false,
        error: false,
    },
    unarchiveRfid: {
        data: [],
        isLoading: false,
        error: false,
    },

    changeStatus: {
        data: [],
        isLoading: false,
        error: false,
    },
    driversByOperator: {
        data: [],
        isLoading: false,
        error: false,
    },
}



export const fetchDriverList = createAsyncThunk('driver/getDriverList', async (data, { rejectWithValue }) => {
    try {
        const response = await DriverService.getDriverList()
        return [...response.data]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const fetchDriverDetail = createAsyncThunk('driver/getDriverDetail', async (data, { rejectWithValue }) => {
    try {
        const response = await DriverService.getDriverDetail(data)
        return response.data
    } catch (err) {
        console.log(err)
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const fetchDriverByOperator = createAsyncThunk('driver/fetchDriverByOperator', async (data, { rejectWithValue }) => {
    try {
        const response = await DriverService.getDriversByOperatorId(data)
        return response.data.drivers
    } catch (err) {
        console.log(err)
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const addDriver = createAsyncThunk('driver/addDriver', async (data, { rejectWithValue }) => {
    try {
        const response = await DriverService.addDriver(data)
        return [response]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const updateDriver = createAsyncThunk('driver/updateDriver', async (data, { rejectWithValue }) => {
    try {
        const response = await DriverService.updateDriver(data)
        return [response]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const updateAddress = createAsyncThunk('driver/updateAddress', async (data, { rejectWithValue }) => {
    try {
        const response = await DriverService.updateAddress(data)
        return [response]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const updateVehicle = createAsyncThunk('driver/updateVehicle', async (data, { rejectWithValue }) => {
    try {
        const response = await DriverService.updateVehicle(data)
        return [response]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const fetchRfidList = createAsyncThunk('driver/getRfidList', async (params, { rejectWithValue }) => {
    try {
        const response = await DriverService.getRfidList(params)
        // console.log(response)
        return [...response.data]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const addBalance = createAsyncThunk('driver/addBalance', async (params, { rejectWithValue }) => {
    try {
        const response = await DriverService.addBalance(params)
        return [...response.data]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const addAddresses = createAsyncThunk('driver/addAddresses', async (params, { rejectWithValue }) => {
    try {
        const response = await DriverService.addAddresses(params)
        return [...response.data]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const addVehicles = createAsyncThunk('driver/addVehicles', async (params, { rejectWithValue }) => {
    try {
        const response = await DriverService.addVehicles(params)
        return [...response.data]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const addRfid = createAsyncThunk('driver/addRfid', async (params, { rejectWithValue }) => {
    try {
        const response = await DriverService.addRfid(params)
        return [...response.data]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const changeStatus = createAsyncThunk('driver/changeStatus', async (params, { rejectWithValue }) => {
    try {
        const response = await DriverService.changeStatus(params)
        return [...response.data]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const deleteDriver = createAsyncThunk('driver/deleteDriver', async (params, { rejectWithValue }) => {
    try {
        await DriverService.deleteDriver(params)
        return params
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const deleteRfid = createAsyncThunk('driver/deleteRfid', async (params, { rejectWithValue }) => {
    try {
        await DriverService.deleteRfid(params)
        return params
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const unarchiveRfid = createAsyncThunk('driver/unarchiveRfid', async (params, { rejectWithValue }) => {
    try {
        await DriverService.unarchiveRfid(params)
        return params
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})


export const driverSlice = createSlice({
    name: 'driver',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchDriverList.pending, (state, action) => {
                state.driverList.isLoading = true
            })
            .addCase(fetchDriverList.rejected, (state, action) => {
                state.driverList.isLoading = false
                state.driverList.error = action.error.message
            })
            .addCase(fetchDriverList.fulfilled, (state, action) => {
                state.driverList.isLoading = false
                state.driverList.error = false
                state.driverList.data = action.payload
            })

            .addCase(addDriver.pending, (state, action) => {
                state.driverAdd.isLoading = true
            })
            .addCase(addDriver.rejected, (state, action) => {
                state.driverAdd.isLoading = false
                state.driverAdd.error = action.error.message
            })
            .addCase(addDriver.fulfilled, (state, action) => {
                state.driverAdd.isLoading = false
                state.driverAdd.error = false
                state.driverAdd.data = action.payload
                state.driverAdd.success = true
            })

            .addCase(updateDriver.pending, (state, action) => {
                state.driverUpdate.isLoading = true
            })
            .addCase(updateDriver.rejected, (state, action) => {
                state.driverUpdate.isLoading = false
                state.driverUpdate.error = action.error.message
            })
            .addCase(updateDriver.fulfilled, (state, action) => {
                state.driverUpdate.isLoading = false
                state.driverUpdate.error = false
                state.driverUpdate.success = true
            })

            .addCase(updateAddress.pending, (state, action) => {
                state.addressUpdate.isLoading = true
            })
            .addCase(updateAddress.rejected, (state, action) => {
                state.addressUpdate.isLoading = false
                state.addressUpdate.error = action.error.message
            })
            .addCase(updateAddress.fulfilled, (state, action) => {
                state.addressUpdate.isLoading = false
                state.addressUpdate.error = false
                state.addressUpdate.success = true
            })

            .addCase(updateVehicle.pending, (state, action) => {
                state.vehicleUpdate.isLoading = true
            })
            .addCase(updateVehicle.rejected, (state, action) => {
                state.vehicleUpdate.isLoading = false
                state.vehicleUpdate.error = action.error.message
            })
            .addCase(updateVehicle.fulfilled, (state, action) => {
                state.vehicleUpdate.isLoading = false
                state.vehicleUpdate.error = false
                state.vehicleUpdate.success = true
            })

            .addCase(fetchDriverDetail.pending, (state, action) => {
                state.driverDetail.isLoading = true
            })
            .addCase(fetchDriverDetail.rejected, (state, action) => {
                state.driverDetail.isLoading = false
                state.driverDetail.error = action.error.message
            })
            .addCase(fetchDriverDetail.fulfilled, (state, action) => {
                state.driverDetail.isLoading = false
                state.driverDetail.error = false
                state.driverDetail.data = action.payload
            })

            .addCase(fetchDriverByOperator.pending, (state, action) => {
                state.driversByOperator.isLoading = true
            })
            .addCase(fetchDriverByOperator.rejected, (state, action) => {
                state.driversByOperator.isLoading = false
                state.driversByOperator.error = action.error.message
            })
            .addCase(fetchDriverByOperator.fulfilled, (state, action) => {
                state.driversByOperator.isLoading = false
                state.driversByOperator.error = false
                state.driversByOperator.data = action.payload
            })

            .addCase(fetchRfidList.pending, (state, action) => {
                state.rfidList.isLoading = true
            })
            .addCase(fetchRfidList.rejected, (state, action) => {
                state.rfidList.isLoading = false
                state.rfidList.error = action.error.message
            })
            .addCase(fetchRfidList.fulfilled, (state, action) => {
                state.rfidList.isLoading = false
                state.rfidList.error = false
                state.rfidList.data = action.payload
            })

            .addCase(addBalance.pending, (state, action) => {
                state.addBalance.isLoading = true
            })
            .addCase(addBalance.rejected, (state, action) => {
                state.addBalance.isLoading = false
                state.addBalance.error = action.error.message
            })
            .addCase(addBalance.fulfilled, (state, action) => {
                state.addBalance.isLoading = false
                state.addBalance.error = false
                state.addBalance.data = action.payload
            })

            .addCase(addAddresses.pending, (state, action) => {
                state.addAddresses.isLoading = true
            })
            .addCase(addAddresses.rejected, (state, action) => {
                state.addAddresses.isLoading = false
                state.addAddresses.error = action.error.message
            })
            .addCase(addAddresses.fulfilled, (state, action) => {
                state.addAddresses.isLoading = false
                state.addAddresses.error = false
                state.addAddresses.data = action.payload
            })

            .addCase(addVehicles.pending, (state, action) => {
                state.addVehicles.isLoading = true
            })
            .addCase(addVehicles.rejected, (state, action) => {
                state.addVehicles.isLoading = false
                state.addVehicles.error = action.error.message
            })
            .addCase(addVehicles.fulfilled, (state, action) => {
                state.addVehicles.isLoading = false
                state.addVehicles.error = false
                state.addVehicles.data = action.payload
            })

            .addCase(addRfid.pending, (state, action) => {
                state.addRfid.isLoading = true
            })
            .addCase(addRfid.rejected, (state, action) => {
                state.addRfid.isLoading = false
                state.addRfid.error = action.error.message
            })
            .addCase(addRfid.fulfilled, (state, action) => {
                state.addRfid.isLoading = false
                state.addRfid.error = false
                state.addRfid.data = action.payload
            })


            
            .addCase(deleteDriver.fulfilled, (state, action) => {
                state.driverList.data = state.driverList.data.filter((driver) => driver.id !== action.payload)
            })

            .addCase(deleteRfid.fulfilled, (state, action) => {
                state.rfidList.data = state.rfidList.data.filter((rfidcard) => rfidcard.id !== action.payload)
            })

            .addCase(unarchiveRfid.pending, (state, action) => {
                state.unarchiveRfid.isLoading = true
            })
            .addCase(unarchiveRfid.rejected, (state, action) => {
                state.unarchiveRfid.isLoading = false
                state.unarchiveRfid.error = action.error.message
            })
            .addCase(unarchiveRfid.fulfilled, (state, action) => {
                state.unarchiveRfid.isLoading = false
                state.unarchiveRfid.error = false
                state.unarchiveRfid.data = action.payload
            })

           
    },
})

export const driverList = (state) => state.driver.driverList
export const getDriverList = (state) => state.driver.driverList.data

export default driverSlice.reducer
