import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'

import RemoteTriggerService from 'services/RemoteTriggerService'

export const initialState = {
    sendMessage: {
        data: [],
        isLoading: false,
        error: false,
    },

    unlockConnector: {
        data: [],
        isLoading: false,
        error: false,
    },

    resetConnector: {
        data: [],
        isLoading: false,
        error: false,
    },

    startTransaction: {
        data: [],
        isLoading: false,
        error: false
    },

    stopTransaction: {
        data: [],
        isLoading: false,
        error: false
    },

    updateFirmware: {
        data: [],
        isLoading: false,
        error: false
    },

    getDiagnostic: {
        data: [],
        isLoading: false,
        error: false
    }
}


export const sendMessage = createAsyncThunk('remoteTrigger/sendMessage', async (data, { rejectWithValue }) => {
    try {
        const response = await RemoteTriggerService.sendMessage(data)
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})


export const unlockConnector = createAsyncThunk('remoteTrigger/unlockConnector', async (data, { rejectWithValue }) => {
    try {
        const response = await RemoteTriggerService.unlockConnector(data)
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const resetConnector = createAsyncThunk('remoteTrigger/resetConnector', async (data, { rejectWithValue }) => {
    try {
        const response = await RemoteTriggerService.resetConnector(data)
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const startTransaction = createAsyncThunk('remoteTrigger/startTransaction', async (data, { rejectWithValue }) => {
    try {
        const response = await RemoteTriggerService.startTransaction(data)
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const stopTransaction = createAsyncThunk('remoteTrigger/stopTransaction', async (data, { rejectWithValue }) => {
    try {
        const response = await RemoteTriggerService.stopTransaction(data)
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const updateFirmware = createAsyncThunk('remoteTrigger/updateFirmware', async (data, { rejectWithValue }) => {
    try {
        const response = await RemoteTriggerService.updateFirmware(data)
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const getDiagnostic = createAsyncThunk('remoteTrigger/getDiagnostic', async (data, { rejectWithValue }) => {
    try {
        const response = await RemoteTriggerService.getDiagnostic(data)
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const remoteTriggerSlice = createSlice({
    name: 'remoteTrigger',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            
            .addCase(sendMessage.pending, (state, action) => {
                state.sendMessage.isLoading = true
            })
            .addCase(sendMessage.rejected, (state, action) => {
                state.sendMessage.isLoading = false
                state.sendMessage.error = true
            })
            .addCase(sendMessage.fulfilled, (state, action) => {
                state.sendMessage.isLoading = false
                state.sendMessage.error = false
                state.sendMessage.data = [...state.sendMessage.data, action.payload]
            })

            .addCase(unlockConnector.pending, (state, action) => {
                state.unlockConnector.isLoading = true
            })
            .addCase(unlockConnector.rejected, (state, action) => {
                state.unlockConnector.isLoading = false
                state.unlockConnector.error = true
            })
            .addCase(unlockConnector.fulfilled, (state, action) => {
                state.unlockConnector.isLoading = false
                state.unlockConnector.error = false
                state.unlockConnector.data = [...state.unlockConnector.data, action.payload]
            })

            .addCase(resetConnector.pending, (state, action) => {
                state.resetConnector.isLoading = true
            })
            .addCase(resetConnector.rejected, (state, action) => {
                state.resetConnector.isLoading = false
                state.resetConnector.error = true
            })
            .addCase(resetConnector.fulfilled, (state, action) => {
                state.resetConnector.isLoading = false
                state.resetConnector.error = false
                state.resetConnector.data = [...state.resetConnector.data, action.payload]
            })

            .addCase(startTransaction.pending, (state, action) => {
                state.startTransaction.isLoading = true
            })
            .addCase(startTransaction.rejected, (state, action) => {
                state.startTransaction.isLoading = false
                state.startTransaction.error = true
            })
            .addCase(startTransaction.fulfilled, (state, action) => {
                state.startTransaction.isLoading = false
                state.startTransaction.error = false
                state.startTransaction.data = [...state.startTransaction.data, action.payload]
            })
            
            .addCase(stopTransaction.pending, (state, action) => {
                state.stopTransaction.isLoading = true
            })
            .addCase(stopTransaction.rejected, (state, action) => {
                state.stopTransaction.isLoading = false
                state.stopTransaction.error = true
            })
            .addCase(stopTransaction.fulfilled, (state, action) => {
                state.stopTransaction.isLoading = false
                state.stopTransaction.error = false
                state.stopTransaction.data = [...state.stopTransaction.data, action.payload]
            })

            .addCase(updateFirmware.pending, (state, action) => {
                state.updateFirmware.isLoading = true
            })
            .addCase(updateFirmware.rejected, (state, action) => {
                state.updateFirmware.isLoading = false
                state.updateFirmware.error = true
            })
            .addCase(updateFirmware.fulfilled, (state, action) => {
                state.updateFirmware.isLoading = false
                state.updateFirmware.error = false
                state.updateFirmware.data = [...state.updateFirmware.data, action.payload]
            })

            .addCase(getDiagnostic.pending, (state, action) => {
                state.getDiagnostic.isLoading = true
            })
            .addCase(getDiagnostic.rejected, (state, action) => {
                state.getDiagnostic.isLoading = false
                state.getDiagnostic.error = true
            })
            .addCase(getDiagnostic.fulfilled, (state, action) => {
                state.getDiagnostic.isLoading = false
                state.getDiagnostic.error = false
                state.getDiagnostic.data = [...state.getDiagnostic.data, action.payload]
            })
    },
})

export default remoteTriggerSlice.reducer
