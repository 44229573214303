import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import TariffService from 'services/TariffService'

export const initialState = {
    tariffList: {
        data: [],
        isLoading: false,
        error: false,
    },

    tariffDetail: {
        data: [],
        isLoading: false,
        error: false,
    },

    tariffAdd: {
        data: [],
        isLoading: null,
        error: null,
        success: null,
    },

    tariffEdit: {
        data: [],
        isLoading: null,
        error: null,
        success: null
    },
    chargePointAvailableTariffs: {
        data: [],
        isLoading: false,
        error: false    
    }
}

export const fetchTariffList = createAsyncThunk('tariff/getTariffList', async (data, { rejectWithValue }) => {
    try {
        const response = await TariffService.getTariffList()
        return [...response.data]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const fetchTariffDetail = createAsyncThunk('tariff/getTariffDetail', async (data, { rejectWithValue }) => {
    try {
        const response = await TariffService.getTariffDetail(data)
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const addTariff = createAsyncThunk('tariff/addTariff', async (data, { rejectWithValue }) => {
    try {
        const response = await TariffService.addTariff(data)
        return [response]
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const updateTariff = createAsyncThunk('tariff/updateTariff', async(data, {rejectWithValue }) => {
    try {
        const response = await TariffService.updateTariff(data)
        return response
    } catch (err) {
        console.log(err)
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const deleteTariff = createAsyncThunk('tariff/deleteTariff', async (params, { rejectWithValue }) => {
    try {
        await TariffService.deleteTariff(params)
        return params
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const getChargePointAvailableTariffs = createAsyncThunk('tariff/getChargePointAvailableTariffs', async (params, { rejectWithValue }) => {
    try {
        const response = await TariffService.getChargePointAvailableTariffs(params)
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const tariffSlice = createSlice({
    name: 'tariff',
    initialState,
    reducers: {
        // updateAddTariffStatus(state, action) {
        //     state.addStatus = action.payload
        // },
        // editTariff: (state, action) => {
        //     state.tariffDetail.data[action.payload.id] = action.payload
        // },
    },
    extraReducers(builder) {
        builder
            // List Tariffs
            .addCase(fetchTariffList.pending, (state, action) => {
                state.tariffList.isLoading = true
            })
            .addCase(fetchTariffList.rejected, (state, action) => {
                state.tariffList.isLoading = false
                state.tariffList.error = action.error.message
            })
            .addCase(fetchTariffList.fulfilled, (state, action) => {
                state.tariffList.isLoading = false
                state.tariffList.error = false
                state.tariffList.data = action.payload
            })

            // Tariff Detail
            .addCase(fetchTariffDetail.pending, (state, action) => {
                state.tariffDetail.isLoading = true
            })
            .addCase(fetchTariffDetail.rejected, (state, action) => {
                state.tariffDetail.error = action.error.message
            })
            .addCase(fetchTariffDetail.fulfilled, (state, action) => {
                state.tariffDetail.isLoading = false
                state.tariffDetail.error = false
                state.tariffDetail.data = action.payload
            })

            // Add Tariff
            .addCase(addTariff.pending, (state, action) => {
                state.tariffAdd.isLoading = true
            })
            .addCase(addTariff.rejected, (state, action) => {
                state.tariffAdd.isLoading = false
                state.tariffAdd.error = action.error.message
            })
            .addCase(addTariff.fulfilled, (state, action) => {
                state.tariffAdd.isLoading = false
                state.tariffAdd.error = false
                state.tariffAdd.success = true
                state.tariffList.data = action.payload
            })

            // Edit Tariff
            .addCase(updateTariff.pending, (state, action) => {
                state.tariffEdit.isLoading = true
            })
            .addCase(updateTariff.rejected, (state,action) => {
                state.tariffEdit.isLoading = false
                state.tariffEdit.error = action.error.message
            })
            .addCase(updateTariff.fulfilled, (state, action) => {
                state.tariffEdit.isLoading = false
                state.tariffEdit.error = false
                state.tariffEdit.success = true

                // state.tariffList.data = state.tariffList.map(tariff=>{
                //     if(action.payload.id === tariff.id) {
                //         tariff.name = action.payload.name
                //         tariff.type = action.payload.type
                //         tariff.fixedPriceForRegisterUser = action.payload.fixedPriceForRegisterUser
                //         tariff.provisionForRegisterUser = action.payload.provisionForRegisterUser
                //         tariff.priceForRegisterUser = action.payload.priceForRegisterUser
                //         tariff.overLimitForRegisterUser = action.payload.overLimitForRegisterUser
                //         tariff.overLimitPriceForRegisterUser = action.payload.overLimitPriceForRegisterUser
                //         tariff.fixedPriceForUnregisterUser = action.payload.fixedPriceForUnregisterUser
                //         tariff.provisionForUnregisterUser = action.payload.provisionForUnregisterUser
                //         tariff.priceForUnregisterUser = action.payload.priceForUnregisterUser
                //         tariff.overLimitForUnregisterUser = action.payload.overLimitForUnregisterUser
                //         tariff.overLimitPriceForUnregisterUser = action.payload.overLimitPriceForUnregisterUser
                //         tariff.operatorId = action.payload.operatorId
                //     }
                //     return tariff;
                // })
            })


            // Delete Tariff
            .addCase(deleteTariff.fulfilled, (state, action) => {
                state.tariffList.data = state.tariffList.data.filter((tariff) => tariff.id !== action.payload);
            })

            // Chargepoint available Tariffs
            .addCase(getChargePointAvailableTariffs.pending, (state, action) => {
                state.chargePointAvailableTariffs.isLoading = true
            })
            .addCase(getChargePointAvailableTariffs.rejected, (state, action) => {
                state.chargePointAvailableTariffs.error = action.error.message
            })
            .addCase(getChargePointAvailableTariffs.fulfilled, (state, action) => {
                state.chargePointAvailableTariffs.isLoading = false
                state.chargePointAvailableTariffs.error = false
                state.chargePointAvailableTariffs.data = action.payload
            })
    },
})

// **
export const tariffList = (state) => state.tariff.tariffList
export const tariffDetail = (state) => state.tariff.tariffDetail
export const tariffAdd = (state) => state.tariff.tariffAdd
export const tariffEdit = (state) => state.tariff.tariffEdit
// export const chargePointAvailableTariffs = (state) => state.tariff.chargePointAvailableTariffs

export const getTariffList = (state) => state.tariff.tariffList.data
export const getTariffListIsLoading = (state) => state.tariff.tariffList.isLoading
export const getTariffListError = (state) => state.tariff.tariffList.error

export const getTariffDetail = (state) => state.tariff.tariffDetail.data

export const getTariffDetailIsLoading = (state) => state.tariff.tariffDetail.isLoading
export const getTariffDetailError = (state) => state.tariff.tariffDetail.error

export const tariffAddIsLoading = (state) => state.tariff.tariffAdd.isLoading
export const tariffAddError = (state) => state.tariff.tariffAdd.error

export const getChargePointAvailableTariffsData = (state) => state.tariff.chargePointAvailableTariffs.data
// export const { updateAddTariffStatus } = tariffSlice.actions

export default tariffSlice.reducer
