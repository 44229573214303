import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import BookingService from 'services/BookingService'

export const initialState = {
    bookingList: {
        data: [],
        isLoading: null,
        error: null,
        success: null,
    },

    bookingAdd: {
        data: [],
        isLoading: null,
        error: null,
        success: null,
    },

    bookingEdit: {
        data: [],
        isLoading: null,
        error: null,
        success: null,
    },

}

export const fetchBookingList = createAsyncThunk('booking/getBookingList', async (data, { rejectWithValue }) => {
    try {
        const response = await BookingService.getBookingList(data)
        return [...response.data]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const addBooking = createAsyncThunk('booking/addBooking', async (data, { rejectWithValue }) => {
     
    try {
        const response = await BookingService.addBooking(data)
        return [response]
    } catch (err) {
        return rejectWithValue(err.response?.error || 'Error')
    }
})

export const updateBooking = createAsyncThunk('booking/updateBooking', async(data, {rejectWithValue }) => {
    try {
        const response = await BookingService.updateBooking(data)
        return [...response]
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const deleteBooking = createAsyncThunk('booking/deleteBooking', async (params, { rejectWithValue }) => {
    try {
        await BookingService.deleteBooking(params)
        return params
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})



export const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        updateAddBookingStatus(state, action) {
            state.addBookingStatus = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchBookingList.pending, (state, action) => {
                state.bookingList.isLoading = true
            })
            .addCase(fetchBookingList.rejected, (state, action) => {
                state.bookingList.isLoading = false
                state.bookingList.error = action.error.message
            })
            .addCase(fetchBookingList.fulfilled, (state, action) => {
                state.bookingList.isLoading = false
                state.bookingList.error = false
                state.bookingList.data = action.payload
            })


            .addCase(addBooking.pending, (state, action) => {
                state.bookingAdd.isLoading = true
            })
            .addCase(addBooking.rejected, (state, action) => {
                state.bookingAdd.isLoading = false
                state.bookingAdd.error = true
            })
            .addCase(addBooking.fulfilled, (state, action) => {
                state.bookingAdd.isLoading = false
                state.bookingAdd.error = false
                state.bookingAdd.data = [...state.bookingList.data, action.payload]
                state.bookingAdd.success = true
            })
            


            // Edit user
            .addCase(updateBooking.pending, (state, action) => {
                state.bookingEdit.isLoading = true
            })
            .addCase(updateBooking.rejected, (state,action) => {
                state.bookingEdit.isLoading = false
                state.bookingEdit.error = action.error.message
            })
            .addCase(updateBooking.fulfilled, (state, action) => {
                state.bookingEdit.isLoading = false
                state.bookingEdit.error = false
                state.bookingEdit.success = true

                state.bookingList.data = state.bookingList.map(booking=>{
                    if ( booking.id === action.payload.id) {
                        booking.name = action.payload.name
                    }
                    return booking;
                })
            })

            

            .addCase(deleteBooking.fulfilled, (state, action) => {
                state.bookingList.data = state.bookingList.data.filter((booking) => booking.id !== action.payload);
            })




    },
})

export const bookingList = (state) => state.booking.bookingList

export const getBookingList = (state) => state.booking.bookingList.data
export const addBookingStatus = (state) => state.booking.addBookingStatus
export const { updateAddBookingStatus } = bookingSlice.actions

export default bookingSlice.reducer
