import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { notification } from 'antd';

import ConnectorService from 'services/ConnectorService'

export const initialState = {
    connectorList: {
        data: [],
        isLoading: false,
        error: false,
    },
    connectorAdd: {
        data: [],
        isLoading: false,
        error: false,
    },
    connectorDetail: {
        data: [],
        isLoading: false,
        error: false,
    },
    connectorEdit: {
        data: [],
        isLoading: false,
        error: false
    },
    connectorAnalytics: {
        data: [],
        isLoading: false,
        error: false
    }
}

export const fetchConnectorList = createAsyncThunk('connector/getConnectors', async (data, { rejectWithValue }) => {
    try {
        const response = await ConnectorService.getConnectors(data)
        return [...response.data]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})


export const fetchConnectorDetail = createAsyncThunk('connector/getConnectorDetail', async (params, { rejectWithValue }) => {
    try {
        const response = await ConnectorService.getConnectorDetail(params)
        return response.data
    } catch (err) {
        console.log(err)
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})


export const fetchConnectorAnalytics = createAsyncThunk('connector/getConnectorAnalytics', async (params, { rejectWithValue }) => {
    try {
        const response = await ConnectorService.getConnectorAnalytics(params)
        return response.data
    } catch (err) {
        console.log(err)
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const addConnector = createAsyncThunk('connector/addConnector', async (data, { rejectWithValue }) => {
    try {
        const response = await ConnectorService.addConnector(data)
        return response.data
    } catch (err) {
        console.log(err.response?.data?.error)
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const updateConnector = createAsyncThunk('connector/updateConnector', async(data, {rejectWithValue }) => {
     
    try {
        const response = await ConnectorService.updateConnector(data)
        return [...response]
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const archiveConnector = createAsyncThunk('connector/archiveConnector', async(data, {rejectWithValue }) => {
     
    try {
        const response = await ConnectorService.archiveConnector(data)
        return [...response]
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})



export const unarchiveConnector = createAsyncThunk('connector/unarchiveConnector', async (params, { rejectWithValue }) => {
    try {
        await ConnectorService.unarchiveConnector(params)
        return params
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const connectorSlice = createSlice({
    name: 'connector',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            // Connector list
            .addCase(fetchConnectorList.pending, (state, action) => {
                state.connectorList.isLoading = true
            })
            .addCase(fetchConnectorList.rejected, (state, action) => {
                state.connectorList.isLoading = false
                state.connectorList.error = true
            })
            .addCase(fetchConnectorList.fulfilled, (state, action) => {
                state.connectorList.isLoading = false
                state.connectorList.error = false
                state.connectorList.data = action.payload
            })

            // Add Connector
            .addCase(addConnector.pending, (state, action) => {
                state.connectorAdd.isLoading = true
            })
            .addCase(addConnector.rejected, (state, action) => {
                state.connectorAdd.isLoading = false
                state.connectorAdd.error = true
            })
            .addCase(addConnector.fulfilled, (state, action) => {
                state.connectorAdd.isLoading = false
                state.connectorAdd.error = false
                state.connectorList.data = [...state.connectorList.data, action.payload]
            })

            // Connector Detail
            .addCase(fetchConnectorDetail.pending, (state, action) => {
                state.connectorDetail.isLoading = true
            })
            .addCase(fetchConnectorDetail.rejected, (state, action) => {
                state.connectorDetail.error = action.error.message
            })
            .addCase(fetchConnectorDetail.fulfilled, (state, action) => {
                state.connectorDetail.isLoading = false
                state.connectorDetail.error = false
                state.connectorDetail.data = action.payload
            })

              // Connector Analytics
            .addCase(fetchConnectorAnalytics.pending, (state, action) => {
                state.connectorAnalytics.isLoading = true
            })
            .addCase(fetchConnectorAnalytics.rejected, (state, action) => {
                state.connectorAnalytics.error = true
            })
            .addCase(fetchConnectorAnalytics.fulfilled, (state, action) => {
                state.connectorAnalytics.isLoading = false
                state.connectorAnalytics.error = false
                state.connectorAnalytics.data = [...state.connectorAnalytics.data, action.payload]
            })

            // Edit connector 
            .addCase(updateConnector.pending, (state, action) => {
                state.connectorEdit.isLoading = true
            })
            .addCase(updateConnector.rejected, (state,action) => {
                state.connectorEdit.isLoading = false
                state.connectorEdit.error = action.error.message
            })
            .addCase(updateConnector.fulfilled, (state, action) => {
                state.connectorEdit.isLoading = false
                state.connectorEdit.error = false
                state.connectorEdit.success = true

                state.connectorList.data = state.connectorList.map(connector => {
                    if(action.payload.id === connector.id) {
                        
                        connector.id = action.payload.id
                        connector.chargePointId = action.payload.chargePointId
                        connector.connectorType = action.payload.connectorType
                        connector.maximumPower = action.payload.maximumPower
                        connector.maximumChargeDuration = action.payload.maximumChargeDuration
                        connector.minimumChargeDuration = action.payload.minimumChargeDuration
                        connector.tariffId = action.payload.tariffId
                    
                    }
                    return connector
                })
            })


            //  // Delete Device
            //  .addCase(deleteConnector.fulfilled, (state, action) => {
            //     state.connectorList.data = state.connectorList.data.filter((connector) => connector.id !== action.payload.id);
            // })
    },
})

export default connectorSlice.reducer
