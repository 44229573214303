import fetch from 'auth/FetchInterceptor'

const LogService = {}
// prettier-ignore
LogService.getAuditLogs = function (params) {
    // params : userId
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/AuditLogs/${params}`,
        method: 'get',
        params,
    })
}

LogService.getDiagnosticFiles = function (params) {
    // params : deviceId
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/Ftp/GetLogs',
        method: 'get',
        params: {
            chargePointId: params
        }
    })
}

LogService.getMaintenanceLogs = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`messageTrace/MaintenanceLogs?ChargePointId=${params.chargePointId}&index=${params.index}&size=10`,
        method: 'get',
        params,
    })
}

export default LogService
