import fetch from 'auth/FetchInterceptor'

const ConnectorService = {}
// prettier-ignore
ConnectorService.getConnectors = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/connector?connectorId=${params}`,
        method: 'get',
        params,
    })
}

ConnectorService.addConnector = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/connector',
        method: 'post',
        data: data,
    })
}

ConnectorService.getConnectorDetail = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/connector?chargePointId=${params}`,
        method: 'get',
        params,
    })
}

ConnectorService.updateConnector = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/connector/`,
        method: 'put',
        data: data,
    })
}

ConnectorService.deleteConnector = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/connector/`,
        method: 'delete',
        data: data,
    })
}

ConnectorService.getConnectorAnalytics = function (params) {
    console.log(params)
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/Analytics/Connector`,
        method: 'get',
        params,
    })
}

ConnectorService.archiveConnector = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/connector/archive`,
        method: 'put',
        data: data,
    })
}

ConnectorService.unarchiveConnector = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/connector/unarchive`,
        method: 'put',
        data: data,
    })
}


export default ConnectorService
