import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import DriverService from 'services/DriverService'

export const initialState = {
    driverDetail: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
}

export const fetchDriverDetailData = createAsyncThunk(
    'driver/getDriverDetailData',
    async (data, { rejectWithValue }) => {
        try {
            const response = await DriverService.getDriverDetailData(data)
            return response
        } catch (err) {
            console.log(err)
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const editDriverDetailData = createAsyncThunk(
    'driver/editDriverDetailData',
    async (data, { rejectWithValue }) => {
        try {
            const response = await DriverService.editDriverDetailData(data)
            return response
        } catch (err) {
            console.log(err)
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const driverDetailSlice = createSlice({
    name: 'driverDetail',
    initialState,
    reducers: {
        editdriver: (state, action) => {
            state.driverDetail[action.payload.id] = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchDriverDetailData.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchDriverDetailData.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchDriverDetailData.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.driverDetail = action.payload
            })
            .addCase(editDriverDetailData.fulfilled, (state, action) => {
                state.driverDetail = action.payload
            })
    },
})

export const getDriverDetail = (state) => state.driverDetail.driverDetail
export const getDriverDetailStatus = (state) => state.driverDetail.status
export const getDriverDetailError = (state) => state.driverDetail.error

export const { editdriverDetail } = driverDetailSlice.actions

export default driverDetailSlice.reducer
