import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'

import SmartChargingService from 'services/SmartChargingService'

export const initialState = {
    smartChargingList: {
        data: [],
        isLoading: false,
        error: false,
    },

    addSmartChargingGroup: {
        data: [],
        isLoading: false,
        error: false,
        success: null
    },

    addChargePointsToGroup: {
        data: [],
        isLoading: false,
        error: false,
        success: null
    },

    deleteChargePointsFromGroup: {
        data: [],
        isLoading: false,
        error: false,
    },

    smartChargingGroupDetail: {
        data: [],
        isLoading: false,
        error: false,
    },
}

export const fetchSmartChargingList = createAsyncThunk(
    'smartCharging/getSmartChargingList',
    async (data, { rejectWithValue }) => {
        try {
            const response = await SmartChargingService.getSmartChargingList()
            return [...response.data]
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const addSmartChargingGroup = createAsyncThunk(
    'smartCharging/addSmartChargingGroup',
    async (data, { rejectWithValue }) => {
        try {
            const response = await SmartChargingService.addSmartChargingGroup(data)
            return [response]
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const addChargePointsToGroup = createAsyncThunk(
    'smartCharging/addChargePointsToGroup',
    async (data, { rejectWithValue }) => {
        try {
            const response = await SmartChargingService.addChargePointsToGroup(data)
            return [response]
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const deleteSmartChargingGroup = createAsyncThunk(
    'smartCharging/deleteSmartChargingGroup',
    async (data, { rejectWithValue }) => {
        try {
            const response = await SmartChargingService.deleteSmartChargingGroup(data)
            return [...response.data]
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const getSmartChargingGroupDetail = createAsyncThunk(
    'smartCharging/getSmartChargingGroupDetail',
    async (data, { rejectWithValue }) => {
        
        try {
            const response = await SmartChargingService.getSmartChargingGroupDetail(data)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const smartChargingSlice = createSlice({
    name: 'smartCharging',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchSmartChargingList.pending, (state, action) => {
                state.smartChargingList.isLoading = true
            })
            .addCase(fetchSmartChargingList.rejected, (state, action) => {
                state.smartChargingList.isLoading = false
                state.smartChargingList.error = action.error.message
            })
            .addCase(fetchSmartChargingList.fulfilled, (state, action) => {
                state.smartChargingList.isLoading = false
                state.smartChargingList.error = false
                state.smartChargingList.data = action.payload
            })

            /** */
            .addCase(addSmartChargingGroup.pending, (state, action) => {
                state.addSmartChargingGroup.isLoading = true
            })
            .addCase(addSmartChargingGroup.rejected, (state, action) => {
                state.addSmartChargingGroup.isLoading = false
                state.addSmartChargingGroup.error = action.error.message
            })
            .addCase(addSmartChargingGroup.fulfilled, (state, action) => {
                state.addSmartChargingGroup.isLoading = false
                state.addSmartChargingGroup.error = false
                state.addSmartChargingGroup.data = action.payload
                state.addSmartChargingGroup.success = true
            })

            /** */
            .addCase(addChargePointsToGroup.pending, (state, action) => {
                state.addChargePointsToGroup.isLoading = true
            })
            .addCase(addChargePointsToGroup.rejected, (state, action) => {
                state.addChargePointsToGroup.isLoading = false
                state.addChargePointsToGroup.error = action.error.message
            })
            .addCase(addChargePointsToGroup.fulfilled, (state, action) => {
                state.addChargePointsToGroup.isLoading = false
                state.addChargePointsToGroup.error = false
                state.addChargePointsToGroup.data = action.payload
                state.addChargePointsToGroup.success = true

            })

           

            /** */
            .addCase(getSmartChargingGroupDetail.pending, (state, action) => {
                state.smartChargingGroupDetail.isLoading = true
            })
            .addCase(getSmartChargingGroupDetail.rejected, (state, action) => {
                state.smartChargingGroupDetail.isLoading = false
                state.smartChargingGroupDetail.error = action.error.message
            })
            .addCase(getSmartChargingGroupDetail.fulfilled, (state, action) => {
                state.smartChargingGroupDetail.isLoading = false
                state.smartChargingGroupDetail.error = false
                state.smartChargingGroupDetail.data = action.payload
            })
    },
})

export const smartChargingList = (state) => state.smartCharging.smartChargingList
export const smartChargingListData = (state) => state.smartCharging.smartChargingList.data
export const smartChargingGroupDetail = (state) => state.smartCharging.smartChargingGroupDetail.data

export default smartChargingSlice.reducer
