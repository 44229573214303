import antdEnUS from 'antd/es/locale/en_US'
import antdTrTR from 'antd/es/locale/tr_TR'
import en from './locales/en_US.json'
import tr from './locales/tr_TR.json'
import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { THEME_CONFIG } from 'configs/AppConfig'

export const resources = {
    en: {
        translation: en,
        antd: antdEnUS,
    },
    tr: {
        translation: tr,
        antd: antdTrTR,
    },
}

i18n.use(initReactI18next)
    // .use(Backend)
    .init({
        resources,
        // fallbackLng: THEME_CONFIG.locale,
        lng: THEME_CONFIG.locale,
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
