import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/auth/authSlice';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import { notification } from 'antd';
import store from '../store';

const unauthorizedCode = [401, 403];

const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000,
});

const TOKEN_PAYLOAD_KEY = 'Authorization';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

const refreshToken = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}portal/auth/refreshToken`);
        console.log(response)
        const newToken = response.data.token;
        console.log(response.data)
        console.log(response.data.data.token)
        localStorage.setItem(AUTH_TOKEN, response.data.data.token);
        console.log('token updated')
        return newToken;
    } catch (error) {
        localStorage.removeItem(AUTH_TOKEN);
        store.dispatch(signOutSuccess());
        // window.location = '/';
        console.log('logout')
        return Promise.reject(error);
    }
};

service.interceptors.request.use(
    (config) => {
        const jwtToken = `Bearer ${localStorage.getItem(AUTH_TOKEN)}` || null;
        config.headers['Accept-Language'] = localStorage.getItem('BELOCALE');

        if (jwtToken) {
            config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
        }

        return config;
    },
    (error) => {
        
        notification.error({
            message: 'Error',
        });

        
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    (response) => {
        let notificationParam = {
            message: 'Success',
        }
        notificationParam.message = response.data.message
        if (response.data.message) {
            notification.success(notificationParam)
        }
        return response.data;
    },
    async (error) => {
        const originalRequest = error.config;
        let notificationParam = {
            message: 'Error',
        }
        if (error.response.status === 400 || error.response.status === 404 || error.response.status === 429  || error.response.status === 402) {
            notificationParam.message = error.response.data.error
        }
        if (unauthorizedCode.includes(error.response.status) && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function(resolve, reject) {
                    failedQueue.push({ resolve, reject });
                }).then(token => {
                    originalRequest.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${token}`;
                    return axios(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise(function(resolve, reject) {
                refreshToken().then(token => {
                    originalRequest.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${token}`;
                    processQueue(null, token);
                    resolve(axios(originalRequest));
                    window.location.reload()
                }).catch(err => {
                    processQueue(err, null);
                    reject(err);
                }).finally(() => {
                    isRefreshing = false;
                });
            });
        }
        notification.error(notificationParam)

        return Promise.reject(error);
    }
);

export default service;
