import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import LogService from 'services/LogService'

export const initialState = {
    auditLogList: {
        data: [],
        isLoading: false,
        error: false,
    },

    diagnosticFilesList: {
        data: [],
        isLoading: false,
        error: false
    },

    maintenanceLogs: {
        data: [],
        isLoading: false,
        error: false
    }
}

export const fetchAuditLogs = createAsyncThunk('log/getAuditLogs', async (data, { rejectWithValue }) => {
    try {
        const response = await LogService.getAuditLogs(data)
        return [...response.data]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const fetchDiagnosticFiles = createAsyncThunk('log/getDiagnosticFiles', async (data, { rejectWithValue }) => {
    try {
        const response = await LogService.getDiagnosticFiles(data)
        return [...response.data]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})


export const fetchMaintenanceLogs = createAsyncThunk('log/getMaintenanceLogs', async (data, { rejectWithValue }) => {
    try {
        const response = await LogService.getMaintenanceLogs(data)
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const logSlice = createSlice({
    name: 'logs',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            // List Audit Logs
            .addCase(fetchAuditLogs.pending, (state, action) => {
                state.auditLogList.isLoading = true
            })
            .addCase(fetchAuditLogs.rejected, (state, action) => {
                state.auditLogList.isLoading = false
                state.auditLogList.error = action.error.message
            })
            .addCase(fetchAuditLogs.fulfilled, (state, action) => {
                state.auditLogList.isLoading = false
                state.auditLogList.error = false
                state.auditLogList.data = action.payload
            })


            // List Diagnostic Files
            .addCase(fetchDiagnosticFiles.pending, (state, action) => {
                state.diagnosticFilesList.isLoading = true
            })
            .addCase(fetchDiagnosticFiles.rejected, (state, action) => {
                state.diagnosticFilesList.isLoading = false
                state.diagnosticFilesList.error = action.error.message
            })
            .addCase(fetchDiagnosticFiles.fulfilled, (state, action) => {
                state.diagnosticFilesList.isLoading = false
                state.diagnosticFilesList.error = false
                state.diagnosticFilesList.data = action.payload
            })


            .addCase(fetchMaintenanceLogs.pending, (state, action) => {
                state.maintenanceLogs.isLoading = true
            })
            .addCase(fetchMaintenanceLogs.rejected, (state, action) => {
                state.maintenanceLogs.isLoading = false
                state.maintenanceLogs.error = action.error.message
            })
            .addCase(fetchMaintenanceLogs.fulfilled, (state, action) => {
                state.maintenanceLogs.isLoading = false
                state.maintenanceLogs.error = false
                state.maintenanceLogs.data = action.payload
            })
    },
})
export default logSlice.reducer
