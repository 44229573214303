import { combineReducers } from 'redux'
import theme from './slices/theme/themeSlice'
import auth from './slices/auth/authSlice'
import dashboard from './slices/dashboard/dashboardSlice'
import device from './slices/device/deviceSlice'
import connector from './slices/connector/connectorSlice'
import driver from './slices/driver/driverSlice'
import driverDetail from './slices/driver/driverDetailSlice'
import operator from './slices/operator/operatorSlice'
import user from './slices/user/userSlice'
import tariff from './slices/tariff/tariffSlice'
import remoteTrigger from './slices/remoteTrigger/remoteTriggerSlice'
import booking from './slices/booking/bookingSlice'
import logs from './slices/logs/logSlice'
import transaction from './slices/transaction/transactionSlice'
import smartCharging from './slices/smartCharging/smartChargingSlice';

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        dashboard,
        device,
        connector,
        operator,
        user,
        driver,
        driverDetail,
        tariff,
        booking,
        remoteTrigger,
        logs,
        transaction,
        smartCharging,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
