import fetch from 'auth/FetchInterceptor'

const OperatorService = {}

OperatorService.getOperatorList = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/operator/suboperators',
        method: 'get',
        params,
    })
}

OperatorService.getOperatorListArchived = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/operator/suboperatorsarchived',
        method: 'get',
        params,
    })
}

OperatorService.getOperatorDetail = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/operator/${params}`,
        method: 'get',
        params,
    })
}

OperatorService.archiveOperator = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/Operator/ArchiveOperator/${params}`,
        method: 'put',
        params,
    })
}

OperatorService.unarchiveOperator = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/Operator/UnarchiveOperator?operatorId=${params}`,
        method: 'put',
        params,
    })
}


OperatorService.updateOperator = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/operator/UpdateOperatorWithMerchantAsync`,
        method: 'put',
        data: data,
    })
}

OperatorService.addOperator = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/operator/AddOperatorWithMerchantAsync',
        method: 'post',
        data: data,
    })
}

export default OperatorService
