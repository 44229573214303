import fetch from 'auth/FetchInterceptor'

const TariffService = {}

TariffService.getTariffList = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/tariff',
        method: 'get',
        params,
    })
}

TariffService.getTariffDetail = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/tariff/${params.tariffId}`,
        method: 'get',
        params,
    })
}

TariffService.addTariff = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/tariff',
        method: 'post',
        data: data,
    })
}

TariffService.updateTariff = function(data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/tariff',
        method: 'put',
        data: data,
    })
}

TariffService.deleteTariff = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/tariff/${encodeURIComponent(params)}`,
        method: 'delete',
        params
    })
}

TariffService.getChargePointAvailableTariffs = function(params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/tariff/GetChargePointAvailableTariffs/${params}`,
        method: 'get',
        params
    })
}


export default TariffService
