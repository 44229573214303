import fetch from 'auth/FetchInterceptor'

const SmartChargingService = {}

SmartChargingService.getSmartChargingList = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/SmartCharging',
        method: 'get',
        params,
    })
}

SmartChargingService.addSmartChargingGroup = function (data) {
     
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/SmartCharging',
        method: 'post',
        data: data,
    })
}


SmartChargingService.addChargePointsToGroup = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/SmartCharging/AddChargePointsToGroup`,
        method: 'post',
        data: data,
    })
}

SmartChargingService.deleteSmartChargingGroup = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/SmartCharging/${encodeURIComponent(params)}`,
        method: 'delete',
        params,
    })
}

SmartChargingService.getSmartChargingGroupDetail= function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/SmartCharging/SmartChargeGroupDetail/${params}`,
        method: 'get',
        params,
    })
}

export default SmartChargingService
