import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import OperatorService from 'services/OperatorService'

export const initialState = {
    operatorList: {
        data: [],
        isLoading: false,
        error: false,
    },
    operatorListArchived: {
        data: [],
        isLoading: false,
        error: false,
    },
    operatorDetail: {
        data: [],
        isLoading: false,
        error: false,
    },
    operatorAdd: {
        data: [],
        isLoading: false,
        error: false,
        success: null
    },

    operatorEdit: {
        data:[],
        isLoading: false,
        error: false,
        success: null
    }
}

const removeEmptySubOperatorsHelper = (obj) => {
    if (typeof obj === 'object') {
        if (Array.isArray(obj.subOperators) && (obj.subOperators.length === 0 || obj.subOperators[0] == null) ) {
            delete obj.subOperators
        }
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                removeEmptySubOperatorsHelper(obj[key])
            }
        }
    } else if (Array.isArray(obj)) {
        obj.forEach((item) => {
            removeEmptySubOperatorsHelper(item)
        })
    }
}

export const fetchOperatorList = createAsyncThunk('operator/getOperatorList', async (data, { rejectWithValue }) => {
    try {
        const response = await OperatorService.getOperatorList()
        let resp = response.data
        removeEmptySubOperatorsHelper(resp.data)
        return [resp]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const fetchOperatorListArchived = createAsyncThunk('operator/getOperatorListArchived', async (data, { rejectWithValue }) => {
    try {
        const response = await OperatorService.getOperatorListArchived()
        let resp = response.data
        console.log(resp)
        // removeEmptySubOperatorsHelper(resp)
        return [resp]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})


export const fetchOperatorDetail = createAsyncThunk('operator/getOperatorDetail', async (data, { rejectWithValue }) => {
    try {
        const response = await OperatorService.getOperatorDetail(data)
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const updateOperator = createAsyncThunk('operator/updateOperator', async(data, {rejectWithValue }) => {
    try {
        
        const response = await OperatorService.updateOperator(data)
        return response
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const addOperator = createAsyncThunk('operator/addOperator', async (data, { rejectWithValue }) => {
    try {
        const response = await OperatorService.addOperator(data)
        return [response]
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const archiveOperator = createAsyncThunk('operator/archiveOperator', async (params, { rejectWithValue }) => {
    try {
        await OperatorService.archiveOperator(params)
        return params
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})


export const unarchiveOperator = createAsyncThunk('operator/unarchiveOperator', async (params, { rejectWithValue }) => {
    try {
        await OperatorService.unarchiveOperator(params)
        return params
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})


export const operatorSlice = createSlice({
    name: 'operator',
    initialState,
    reducers: {
        // updateAddOperatorStatus(state, action) {
        //     state.addOperatorStatus = action.payload
        // },
    },
    extraReducers(builder) {
        builder
            // List Operators
            .addCase(fetchOperatorList.pending, (state, action) => {
                state.operatorList.isLoading = true
            })
            .addCase(fetchOperatorList.rejected, (state, action) => {
                state.operatorList.isLoading = false
                state.operatorList.error = true
            })
            .addCase(fetchOperatorList.fulfilled, (state, action) => {
                state.operatorList.isLoading = false
                state.operatorList.error = false
                state.operatorList.data = action.payload
            })

            // List Archived Operators
            .addCase(fetchOperatorListArchived.pending, (state, action) => {
                state.operatorListArchived.isLoading = true
            })
            .addCase(fetchOperatorListArchived.rejected, (state, action) => {
                state.operatorListArchived.isLoading = false
                state.operatorListArchived.error = true
            })
            .addCase(fetchOperatorListArchived.fulfilled, (state, action) => {
                state.operatorListArchived.isLoading = false
                state.operatorListArchived.error = false
                state.operatorListArchived.data = action.payload
            })



            // Get Operator by ID
            .addCase(fetchOperatorDetail.pending, (state, action) => {
                state.operatorDetail.isLoading = true
            })
            .addCase(fetchOperatorDetail.rejected, (state, action) => {
                state.operatorDetail.isLoading = false
                state.operatorDetail.error = true
            })
            .addCase(fetchOperatorDetail.fulfilled, (state, action) => {
                state.operatorDetail.isLoading = false
                state.operatorDetail.error = false
                state.operatorDetail.data = action.payload
            })

            // Add Operator
            .addCase(addOperator.pending, (state, action) => {
                state.operatorAdd.isLoading = true
            })
            .addCase(addOperator.rejected, (state, action) => {
                state.operatorAdd.isLoading = false
                state.operatorAdd.error = true
            })
            .addCase(addOperator.fulfilled, (state, action) => {
                state.operatorAdd.isLoading = false
                state.operatorAdd.error = false
                state.operatorAdd.success = true
                state.operatorAdd.data = [...state.operatorList.data, action.payload]
            })


            // Update Device
            .addCase(updateOperator.pending, (state, action) => {
                state.operatorEdit.isLoading = true
            })
            .addCase(updateOperator.rejected, (state,action) => {
                state.operatorEdit.isLoading = false
                state.operatorEdit.error = action.error.message
            })
            .addCase(updateOperator.fulfilled, (state, action) => {
                state.operatorEdit.isLoading = false
                state.operatorEdit.error = false
                state.operatorEdit.success = true

                // state.operatorList.data = state.operatorList.map(operator=>{
                //     if(action.payload.operatorId === operator.operatorId) {
                //         operatorDetail.parentOperatorId = action.payload.parentOperatorId
                //         operatorDetail.companyName = action.payload.companyName
                //         operatorDetail.email = action.payload.email
                //         operatorDetail.phone = action.payload.phone
                //         operatorDetail.supportEmail = action.payload.supportEmail
                //         operatorDetail.companyLogo = null
                //         operatorDetail.country = action.payload.country
                //         operatorDetail.city = action.payload.city
                //         operatorDetail.heartBeatInterval = action.payload.heartBeatInterval
                //         operatorDetail.minimumBalanceToStartCharging = action.payload.minimumBalanceToStartCharging
                //         operatorDetail.role = action.payload.role
                //     }
                //     return operator;
                // })
            })


    },
})

export const operatorDetail = (state) => state.operator.operatorDetail


export const { updateAddOperatorStatus } = operatorSlice.actions

export default operatorSlice.reducer
