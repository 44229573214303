import fetch from 'auth/FetchInterceptor'
import axios from 'axios'
const AuthService = {}

AuthService.login = function (data) {
    return fetch({
        // url: 'http://192.168.0.13:4000/users/signin',
        url: process.env.REACT_APP_API_URL+'portal/Auth/login',
        method: 'post',
        data: data,
    })
}

AuthService.logout = function () {
    return fetch({
        url: '/auth/logout',
        method: 'post',
    })
}

AuthService.forgotPassword = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/Auth/forgot',
        method: 'post',
        data: data,
    })
}

AuthService.resetPassword = function (data) {

    axios.post(process.env.REACT_APP_API_URL+"portal/User/forgotchangepassword", {'newPassword' : data.newPassword, 'newPasswordRepeat': data.newPasswordRepeat}, {
        headers:{
            'Authorization': 'Bearer ' + data.token
        }
    }).then((response) => {
      console.log(response.status, response.data);
    });

 
}

export default AuthService
