import fetch from 'auth/FetchInterceptor'

const RemoteTriggerService = {}
// prettier-ignore

RemoteTriggerService.sendMessage = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'ocpp/RemoteTrigger/triggerMessage',
        method: 'post',
        data: data,
    })
}

RemoteTriggerService.unlockConnector = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'ocpp/RemoteTrigger/unLockConnector',
        method: 'post',
        data: data,
    })
}

RemoteTriggerService.resetConnector = function (data) {
     
    return fetch({
        url: process.env.REACT_APP_API_URL+'ocpp/RemoteTrigger/reset',
        method: 'post',
        data: data,
    })
}

RemoteTriggerService.startTransaction = function (data) {
     
    return fetch({
        url: process.env.REACT_APP_API_URL+'ocpp/RemoteTrigger/startTransaction',
        method: 'post',
        data: data,
    })
}

RemoteTriggerService.stopTransaction = function (data) {
     
    return fetch({
        url: process.env.REACT_APP_API_URL+'ocpp/RemoteTrigger/stopTransaction',
        method: 'post',
        data: data,
    })
}

RemoteTriggerService.updateFirmware = function (data) {
     
    return fetch({
        url:  process.env.REACT_APP_API_URL+'ocpp/RemoteTrigger/updateFirmware',
        method: 'post',
        data: data,
    })
}

RemoteTriggerService.getDiagnostic = function (data) {
     
    return fetch({
        url: process.env.REACT_APP_API_URL+'ocpp/RemoteTrigger/getDiagnostic',
        method: 'post',
        data: data,
    })
}

export default RemoteTriggerService
