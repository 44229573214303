import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import DashboardService from 'services/DashboardService'
// import axios from 'axios'

export const initialState = {
    analyticsData: {
        data: [],
        isLoading: false,
        error: false,
    },
}

export const fetchAnalyticsData = createAsyncThunk('analytics/getAnalyticsData', async (data, { rejectWithValue }) => {
    try {
        const response = await DashboardService.getAnalyticsData()
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchAnalyticsData.pending, (state, action) => {
                state.analyticsData.isLoading = true
            })
            .addCase(fetchAnalyticsData.rejected, (state, action) => {
                state.analyticsData.isLoading = false
                state.analyticsData.error = action.error.message
            })
            .addCase(fetchAnalyticsData.fulfilled, (state, action) => {
                state.analyticsData.isLoading = false
                state.analyticsData.error = false
                state.analyticsData.data = action.payload
            })
    },
})

export default dashboardSlice.reducer
