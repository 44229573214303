import fetch from 'auth/FetchInterceptor'
import { uploadFirmware } from './../store/slices/device/deviceSlice'

const DeviceService = {}
// prettier-ignore
DeviceService.getDeviceList = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/ChargePoint',
        method: 'get',
        params,
    })
}


DeviceService.getChargePointModels = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/Enum/getChargePointModels',
        method: 'get',
        params,
    })
}


DeviceService.getDeviceListDynamic = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/ChargePoint/GetAllChargePointsDynamic?index=${params.index}&size=10`,
        method: 'post',
        data: params.data,
    })
}

DeviceService.getArchivedDeviceList = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/ChargePoint/GetDeletedChargePointsDynamic?index=${params.index}&size=10`,
        method: 'post',
        data: params.data,
    })
}

DeviceService.getActiveDeviceList = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/ChargePoint/GetActiveChargePointsDynamic?index=${params.index}&size=10`,
        method: 'post',
        data: params.data,
    })
}

DeviceService.getDeviceDetail = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/ChargePoint/${params}`,
        method: 'get',
        params,
    })
}

DeviceService.addDevice = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/ChargePoint',
        method: 'post',
        data: data,
    })
}

DeviceService.updateDevice = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/ChargePoint',
        method: 'put',
        data: data,
    })
}

DeviceService.deleteDevice = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/ChargePoint/${params}`,
        method: 'delete',
        params,
    })
}

DeviceService.unarchiveDevice = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/ChargePoint/UnarchiveChargepoint?chargePointID=${params}`,
        method: 'put',
        params,
    })
}

DeviceService.getOcppLogsReqRes = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`messageTrace/OcppLogs/getLogsRequestResponse?StartDate=${params.startDate}&EndDate=${params.endDate}&ChargePointId=${params.chargePointId}&Types=${params.types}&index=${params.index}&size=10`,
        method: 'get',
    })
}

DeviceService.getOcppLogs = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`messageTrace/OcppLogs/getLogs?StartDate=${params.startDate}&EndDate=${params.endDate}&ChargePointId=${params.chargePointId}&Types=${params.types}&index=${params.index}&size=10`,
        method: 'get',
    })
}

DeviceService.getFirmwareList = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/Ftp/GetFirmwares',
        method: 'get',
        params,
    })
}

DeviceService.uploadFirmware = function (params) {
    console.log(params[0])
    console.log(params[1])
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/Ftp/UploadFirmware`,
        method: 'post',
        body: params[0],
    })
}

DeviceService.getProfile = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/Profile/${params}`,
        method: 'get',
        params,
    })
}

DeviceService.changeConfiguration = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'ocpp/RemoteTrigger/changeConfiguration',
        method: 'post',
        data: data,
    })
}

DeviceService.getConfiguration = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`ocpp/RemoteTrigger/getConfiguration`,
        method: 'post',
        data: data,
    })
}

DeviceService.getDeviceAnalytics = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/Analytics/Chargepoint?chargePointId=${params}`,
        method: 'get',
        params,
    })
}

DeviceService.getCountries = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/Country',
        method: 'get',
        params,
    })
}

DeviceService.getCities = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/Country/GetCities/${params}`,
        method: 'get',
        params,
    })
}



export default DeviceService
