import fetch from 'auth/FetchInterceptor'

const TransactionService = {}
// prettier-ignore

TransactionService.getChargingSessions = function (params) {

    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/Transaction/GetTransactionsDynamic?index=${params.index}&size=10`,
        method: 'post',
        data: params.finalQueryCompleted
    })
}

TransactionService.getChargingSessionsToExcel = function (data) {
    // console.log(data)
    return fetch({
        url: process.env.REACT_APP_API_URL + `portal/Transaction/GetTransactionsExcel`,
        method: 'post',
        data: data,
    })
}

TransactionService.getActiveChargingSessions = function (params) {

    // params : deviceId
    return fetch({
        url: process.env.REACT_APP_API_URL + `portal/Transaction/GetTransactionsDynamic?index=${params.index}&size=10`,
        method: 'post',
        data: params.finalQueryActive,
    })
}

TransactionService.getPaymentsList = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/Payment/GetPaymentTransactions?index=${params.index}&size=10`,
        method: 'post',
        data: params
    })
}

TransactionService.chargeback = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`Payment/refund?pgTranId=${params.pgTranId}&amount=${params.amount}`,
        method: 'post',
        data: params
    })
}


export default TransactionService
