import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import DeviceService from 'services/DeviceService'

export const initialState = {
    deviceList: {
        data: [],
        isLoading: false,
        error: false,
    },

    deviceListDynamic: {
        data: [],
        isLoading: false,
        error: false,
    },

    deviceActiveListDynamic: {
        data: [],
        isLoading: false,
        error: false,
    },

    deviceArchivedListDynamic: {
        data: [],
        isLoading: false,
        error: false,
    },

    chargePointModels: {
        data: [],
        isLoading: false,
        error: false,
    },

    deviceDetail: {
        data: [],
        isLoading: false,
        error: false,
    },

    deviceAdd: {
        data: [],
        isLoading: false,
        error: false,
        success: null,
    },

    deviceEdit: {
        data: [],
        isLoading: false,
        error: false,
        success: null,
    },

    ocppLogsReqRes: {
        data: [],
        isLoading: false,
        error: false,
    },

    ocppLogs: {
        data: [],
        isLoading: false,
        error: false,
    },

    firmwareList: {
        data: [],
        isLoading: false,
        error: false,
    },

    firmwareUpload: {
        data: [],
        isLoading: false,
        error: false,
    },

    firmwareUpdate: {
        data: [],
        isLoading: false,
        error: false,
    },

    initialPlace: {
        data: {},
    },

    getProfile: {
        data: null,
        isLoading: false,
        error: false,
    },

    changeConfiguration: {
        data: [],
        isLoading: false,
        error: false,
    },

    getConfiguration: {
        data: [],
        isLoading: false,
        error: false,
    },

    deviceAnalytics: {
        data: [],
        isLoading: false,
        error: false,
    },

    countries: {
        data: [],
        isLoading: false,
        error: false,
    },

    cities: {
        data: [],
        isLoading: false,
        error: false,
    },


}

export const fetchDeviceList = createAsyncThunk('device/getDeviceList', async (data, { rejectWithValue }) => {
    try {
        const response = await DeviceService.getDeviceList()
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})






export const fetchChargePointModels = createAsyncThunk(
    'device/getChargePointModels',
    async (data, { rejectWithValue }) => {
        try {
            const response = await DeviceService.getChargePointModels()
            return response.data
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const fetchDeviceListDynamic = createAsyncThunk(
    'device/getDeviceListDynamic',
    async (params, { rejectWithValue }) => {
        try {
            const response = await DeviceService.getDeviceListDynamic(params)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)


export const fetchActiveDeviceList = createAsyncThunk(
    'device/getActiveDeviceList',
    async (params, { rejectWithValue }) => {
        try {
            const response = await DeviceService.getActiveDeviceList(params)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const fetchArchivedList = createAsyncThunk('device/getArchivedDeviceList', async (params, { rejectWithValue }) => {
    try {
        const response = await DeviceService.getArchivedDeviceList(params)
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})



export const fetchDeviceDetail = createAsyncThunk('device/getDeviceDetail', async (params, { rejectWithValue }) => {
    try {
        const response = await DeviceService.getDeviceDetail(params)
        return response.data
    } catch (err) {
        console.log(err)
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const addDevice = createAsyncThunk('device/addDevice', async (data, { rejectWithValue }) => {
    try {
        const response = await DeviceService.addDevice(data)
        return [response]
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const deleteDevice = createAsyncThunk('device/deleteDevice', async (params, { rejectWithValue }) => {
    try {
        await DeviceService.deleteDevice(params)
        return params
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})


export const unarchiveDevice = createAsyncThunk('device/unarchiveDevice', async (params, { rejectWithValue }) => {
    try {
        await DeviceService.unarchiveDevice(params)
        return params
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})





export const updateDevice = createAsyncThunk('device/updateDevice', async (data, { rejectWithValue }) => {
    try {
        const response = await DeviceService.updateDevice(data)
        return response
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const fetchOcppLogsReqRes = createAsyncThunk('device/ocppLogsReqRes', async (data, { rejectWithValue }) => {
    try {
        const response = await DeviceService.getOcppLogsReqRes(data)
        return response.data
    } catch (err) {
        console.log(err)
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const fetchOcppLogs = createAsyncThunk('device/ocppLogs', async (data, { rejectWithValue }) => {
    try {
        const response = await DeviceService.getOcppLogs(data)
        return response.data
    } catch (err) {
        console.log(err)
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})
export const fetchFirmwareList = createAsyncThunk('device/getFirmwareList', async (data, { rejectWithValue }) => {
    try {
        const response = await DeviceService.getFirmwareList()
        return [...response.data]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const uploadFirmware = createAsyncThunk('device/uploadFirmware', async (data, { rejectWithValue }) => {
    try {
        const response = await DeviceService.uploadFirmware(data)

        return [...response.data]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const getProfile = createAsyncThunk('device/getProfile', async (params, { rejectWithValue }) => {
    try {
        const response = await DeviceService.getProfile(params)
        return response.data
    } catch (err) {
        console.log(err)
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const changeConfiguration = createAsyncThunk('device/changeConfiguration', async (data, { rejectWithValue }) => {
    try {
        const response = await DeviceService.changeConfiguration(data)
        return [response]
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const getConfiguration = createAsyncThunk('device/getConfiguration', async (data, { rejectWithValue }) => {
    try {
        const response = await DeviceService.getConfiguration(data)
        return response.data
    } catch (err) {
        console.log(err)
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const fetchDeviceAnalytics = createAsyncThunk(
    'device/getDeviceAnalytics',
    async (params, { rejectWithValue }) => {
        try {
            const response = await DeviceService.getDeviceAnalytics(params)
            return response.data
        } catch (err) {
            console.log(err)
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const getCountries = createAsyncThunk('device/countries', async (data, { rejectWithValue }) => {
    try {
        const response = await DeviceService.getCountries(data)
        return response.data
    } catch (err) {
        console.log(err)
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})


export const getCities = createAsyncThunk('device/cities', async (data, { rejectWithValue }) => {
    try {
        const response = await DeviceService.getCities(data)
        return response.data
    } catch (err) {
        console.log(err)
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})


export const deviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers: {
        setOcppLogsReqRes(state, action) {
            state.ocppLogsReqRes.data = []
        },
        setOcppLogs(state, action) {
            state.ocppLogs.data = []
        },
        setInitialPlace(state, action) {
            state.initialPlace.data = action.payload
        },
    },
    extraReducers(builder) {
        builder
            // List Devices
            .addCase(fetchDeviceList.pending, (state, action) => {
                state.deviceList.isLoading = true
            })
            .addCase(fetchDeviceList.rejected, (state, action) => {
                state.deviceList.isLoading = false
                state.deviceList.error = action.error.message
            })
            .addCase(fetchDeviceList.fulfilled, (state, action) => {
                state.deviceList.isLoading = false
                state.deviceList.error = false
                state.deviceList.data = action.payload
            })

           

            .addCase(fetchChargePointModels.pending, (state, action) => {
                state.chargePointModels.isLoading = true
            })
            .addCase(fetchChargePointModels.rejected, (state, action) => {
                state.chargePointModels.isLoading = false
                state.chargePointModels.error = action.error.message
            })
            .addCase(fetchChargePointModels.fulfilled, (state, action) => {
                state.chargePointModels.isLoading = false
                state.chargePointModels.error = false
                state.chargePointModels.data = action.payload
            })

            // List Devices Dynamic
            .addCase(fetchDeviceListDynamic.pending, (state, action) => {
                state.deviceListDynamic.isLoading = true
            })
            .addCase(fetchDeviceListDynamic.rejected, (state, action) => {
                state.deviceListDynamic.isLoading = false
                state.deviceListDynamic.error = action.error.message
            })
            .addCase(fetchDeviceListDynamic.fulfilled, (state, action) => {
                state.deviceListDynamic.isLoading = false
                state.deviceListDynamic.error = false
                state.deviceListDynamic.data = action.payload
            })


            .addCase(fetchActiveDeviceList.pending, (state, action) => {
                state.deviceActiveListDynamic.isLoading = true
            })
            .addCase(fetchActiveDeviceList.rejected, (state, action) => {
                state.deviceActiveListDynamic.isLoading = false
                state.deviceActiveListDynamic.error = action.error.message
            })
            .addCase(fetchActiveDeviceList.fulfilled, (state, action) => {
                state.deviceActiveListDynamic.isLoading = false
                state.deviceActiveListDynamic.error = false
                state.deviceActiveListDynamic.data = action.payload
            })

            .addCase(fetchArchivedList.pending, (state, action) => {
                state.deviceArchivedListDynamic.isLoading = true
            })
            .addCase(fetchArchivedList.rejected, (state, action) => {
                state.deviceArchivedListDynamic.isLoading = false
                state.deviceArchivedListDynamic.error = action.error.message
            })
            .addCase(fetchArchivedList.fulfilled, (state, action) => {
                state.deviceArchivedListDynamic.isLoading = false
                state.deviceArchivedListDynamic.error = false
                state.deviceArchivedListDynamic.data = action.payload
            })



            // Device Detail
            .addCase(fetchDeviceDetail.pending, (state, action) => {
                state.deviceDetail.isLoading = true
            })
            .addCase(fetchDeviceDetail.rejected, (state, action) => {
                state.deviceDetail.error = action.error.message
            })
            .addCase(fetchDeviceDetail.fulfilled, (state, action) => {
                state.deviceDetail.isLoading = false
                state.deviceDetail.error = false
                state.deviceDetail.data = action.payload
            })

            // Add Device
            .addCase(addDevice.pending, (state, action) => {
                state.deviceAdd.isLoading = true
            })
            .addCase(addDevice.rejected, (state, action) => {
                state.deviceAdd.isLoading = false
                state.deviceAdd.error = action.error.message
            })
            .addCase(addDevice.fulfilled, (state, action) => {
                state.deviceAdd.isLoading = false
                state.deviceAdd.error = false
                state.deviceAdd.success = true
                state.deviceList.data = action.payload
            })

            // Update Device
            .addCase(updateDevice.pending, (state, action) => {
                state.deviceEdit.isLoading = true
            })
            .addCase(updateDevice.rejected, (state, action) => {
                state.deviceEdit.isLoading = false
                state.deviceEdit.error = action.error.message
            })
            .addCase(updateDevice.fulfilled, (state, action) => {
                state.deviceEdit.isLoading = false
                state.deviceEdit.error = false
                state.deviceEdit.success = true
            })

            // Delete Device
            .addCase(deleteDevice.fulfilled, (state, action) => {
                state.deviceList.data = state.deviceList.data.filter(
                    (device) => device.chargePointId !== action.payload
                )
            })

            // Message Trace

            .addCase(fetchOcppLogsReqRes.pending, (state, action) => {
                state.ocppLogsReqRes.isLoading = true
            })
            .addCase(fetchOcppLogsReqRes.rejected, (state, action) => {
                state.ocppLogsReqRes.isLoading = false
                state.ocppLogsReqRes.error = action.error.message
            })
            .addCase(fetchOcppLogsReqRes.fulfilled, (state, action) => {
                state.ocppLogsReqRes.isLoading = false
                state.ocppLogsReqRes.error = false
                state.ocppLogsReqRes.data = action.payload
            })

            .addCase(fetchOcppLogs.pending, (state, action) => {
                state.ocppLogs.isLoading = true
            })
            .addCase(fetchOcppLogs.rejected, (state, action) => {
                state.ocppLogs.isLoading = false
                state.ocppLogs.error = action.error.message
            })
            .addCase(fetchOcppLogs.fulfilled, (state, action) => {
                state.ocppLogs.isLoading = false
                state.ocppLogs.error = false
                state.ocppLogs.data = action.payload
            })

            // List Firmwares
            .addCase(fetchFirmwareList.pending, (state, action) => {
                state.firmwareList.isLoading = true
            })
            .addCase(fetchFirmwareList.rejected, (state, action) => {
                state.firmwareList.isLoading = false
                state.firmwareList.error = action.error.message
            })
            .addCase(fetchFirmwareList.fulfilled, (state, action) => {
                state.firmwareList.isLoading = false
                state.firmwareList.error = false

                const modifiedFirmwareList = {
                    data: [],
                }

                action.payload.forEach((item) => {
                   
                        const newEntry = {
                            fileName: item.fileName,
                            downloadLink: item.downloadLink,
                            createdDate: item.createdDate,
                        }
                        modifiedFirmwareList.data.push(newEntry)
                  
                })
                state.firmwareList.data = modifiedFirmwareList
            })

            // upload firmware
            .addCase(uploadFirmware.pending, (state, action) => {
                state.firmwareUpload.isLoading = true
            })
            .addCase(uploadFirmware.rejected, (state, action) => {
                state.firmwareUpload.isLoading = false
                state.firmwareUpload.error = action.error.message
            })
            .addCase(uploadFirmware.fulfilled, (state, action) => {
                state.firmwareUpload.isLoading = false
                state.firmwareUpload.error = false
                state.firmwareUpload.data = action.payload
            })

            // get profile
            .addCase(getProfile.pending, (state, action) => {
                state.getProfile.isLoading = true
            })
            .addCase(getProfile.rejected, (state, action) => {
                state.getProfile.isLoading = false
                state.getProfile.error = action.error.message
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.getProfile.isLoading = false
                state.getProfile.error = false
                state.getProfile.data = action.payload
            })

            // get profile
            .addCase(changeConfiguration.pending, (state, action) => {
                state.changeConfiguration.isLoading = true
            })
            .addCase(changeConfiguration.rejected, (state, action) => {
                state.changeConfiguration.isLoading = false
                state.changeConfiguration.error = action.error.message
            })
            .addCase(changeConfiguration.fulfilled, (state, action) => {
                state.changeConfiguration.isLoading = false
                state.changeConfiguration.error = false
                state.changeConfiguration.data = action.payload
            })

            .addCase(getConfiguration.pending, (state, action) => {
                state.getConfiguration.isLoading = true
            })
            .addCase(getConfiguration.rejected, (state, action) => {
                state.getConfiguration.isLoading = false
                state.getConfiguration.error = action.error.message
            })
            .addCase(getConfiguration.fulfilled, (state, action) => {
                state.getConfiguration.isLoading = false
                state.getConfiguration.error = false
                state.getConfiguration.data = action.payload
            })

            .addCase(fetchDeviceAnalytics.pending, (state, action) => {
                state.deviceAnalytics.isLoading = true
            })
            .addCase(fetchDeviceAnalytics.rejected, (state, action) => {
                state.deviceAnalytics.isLoading = false
                state.deviceAnalytics.error = action.error.message
            })
            .addCase(fetchDeviceAnalytics.fulfilled, (state, action) => {
                state.deviceAnalytics.isLoading = false
                state.deviceAnalytics.error = false
                state.deviceAnalytics.data = action.payload
            })


            .addCase(getCountries.pending, (state, action) => {
                state.countries.isLoading = true
            })
            .addCase(getCountries.rejected, (state, action) => {
                state.countries.isLoading = false
                state.countries.error = action.error.message
            })
            .addCase(getCountries.fulfilled, (state, action) => {
                state.countries.isLoading = false
                state.countries.error = false
                state.countries.data = action.payload
            })


            .addCase(getCities.pending, (state, action) => {
                state.cities.isLoading = true
            })
            .addCase(getCities.rejected, (state, action) => {
                state.cities.isLoading = false
                state.cities.error = action.error.message
            })
            .addCase(getCities.fulfilled, (state, action) => {
                state.cities.isLoading = false
                state.cities.error = false
                state.cities.data = action.payload
            })
    },
})

// **
export const deviceList = (state) => state.device.deviceList
export const deviceDetail = (state) => state.device.deviceDetail
export const deviceAdd = (state) => state.device.deviceAdd

export const getDeviceList = (state) => state.device.deviceList.data
export const getDeviceListIsLoading = (state) => state.device.deviceList.isLoading
export const getDeviceListError = (state) => state.device.deviceList.error

export const getDeviceListDynamic = (state) => state.device.deviceListDynamic.data

export const getDeviceDetail = (state) => state.device.deviceDetail.data
export const getDeviceDetailIsLoading = (state) => state.device.deviceDetail.isLoading
export const getDeviceDetailError = (state) => state.device.deviceDetail.error

export const deviceAddIsLoading = (state) => state.device.deviceAdd.isLoading
export const deviceAddError = (state) => state.device.deviceAdd.error

export const { setOcppLogs } = deviceSlice.actions
export const { setOcppLogsReqRes } = deviceSlice.actions
export const { setInitialPlace } = deviceSlice.actions

export default deviceSlice.reducer
