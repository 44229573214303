import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'reset-password',
        // path: `${AUTH_PREFIX_PATH}/forgotPassword`,
        path: '/forgotPassword',
        component: React.lazy(() => import('views/auth-views/authentication/reset-password')),
    },
    
]

export const protectedRoutes = [
    {
        key: 'error-page',
        // path: `${AUTH_PREFIX_PATH}/forgotPassword`,
        path: `${APP_PREFIX_PATH}/error`,
        component: React.lazy(() => import('views/auth-views/errors/error-page')),
    },
    {
        key: 'dashboard.analytics',
        path: `${APP_PREFIX_PATH}/dashboards/analytics`,
        component: React.lazy(() => import('views/app-views/dashboards/analytics/')),
    },
    {
        key: 'dashboard.map',
        path: `${APP_PREFIX_PATH}/dashboards/map`,
        component: React.lazy(() => import('views/app-views/dashboards/map/')),
    },
    {
        key: 'reports.chargingsessions',
        path: `${APP_PREFIX_PATH}/reports/chargingsessions`,
        component: React.lazy(() => import('views/app-views/reports/chargingSessions/ChargingSessions')),
    },
    {
        key: 'reports.peyments',
        path: `${APP_PREFIX_PATH}/reports/payments`,
        component: React.lazy(() => import('views/app-views/reports/payments/Payments')),
    }, 
    {
        key: 'management.device',
        path: `${APP_PREFIX_PATH}/management/device`,
        component: React.lazy(() => import('views/app-views/management/deviceManagement/')),
    },
    {
        key: 'management.device.detail',
        path: `${APP_PREFIX_PATH}/management/device/detail/:chargePointId`,
        component: React.lazy(() => import('views/app-views/management/deviceManagement/devices/DeviceDetail')),
    },
    {
        key: 'management.device.edit',
        path: `${APP_PREFIX_PATH}/management/device/edit/:id`,
        component: React.lazy(() => import('views/app-views/management/deviceManagement/devices/DeviceEdit')),
    },
    {
        key: 'management.device.add',
        path: `${APP_PREFIX_PATH}/management/device/add`,
        component: React.lazy(() => import('views/app-views/management/deviceManagement/devices/DeviceAdd')),
    },
    {
        key: 'management.device.configuration',
        path: `${APP_PREFIX_PATH}/management/device/detail/:id/configuration`,
        component: React.lazy(() => import('views/app-views/management/deviceManagement/devices/configuration')),
    },
    {
        key: 'management.device.messageTrace',
        path: `${APP_PREFIX_PATH}/management/device/detail/:id/messageTrace`,
        component: React.lazy(() => import('views/app-views/management/deviceManagement/devices/MessageTrace')),
    },
    {
        key: 'management.connector.list',
        path: `${APP_PREFIX_PATH}/management/device/detail/:id/connectors`,
        component: React.lazy(() =>
            import('views/app-views/management/deviceManagement/devices/connectors/ConnectorListDetail')
        ),
    },
    {
        key: 'management.device.firmwareUpdate',
        path: `${APP_PREFIX_PATH}/management/device/firmware`,
        component: React.lazy(() => import('views/app-views/management/deviceManagement/firmware')),
    },

    {
        key: 'management.driver',
        path: `${APP_PREFIX_PATH}/management/driver`,
        component: React.lazy(() => import('views/app-views/management/driverManagement')),
    },
    {
        key: 'management.driver.add',
        path: `${APP_PREFIX_PATH}/management/driver/add`,
        component: React.lazy(() => import('views/app-views/management/driverManagement/drivers/DriverAdd')),
    },
    {
        key: 'management.driver.detail',
        path: `${APP_PREFIX_PATH}/management/driver/detail/:id`,
        component: React.lazy(() => import('views/app-views/management/driverManagement/drivers/DriverDetail')),
    },
    {
        key: 'management.driver.edit',
        path: `${APP_PREFIX_PATH}/management/driver/edit/:id`,
        component: React.lazy(() => import('views/app-views/management/driverManagement/drivers/DriverEdit')),
    },
    {
        key: 'management.operator',
        path: `${APP_PREFIX_PATH}/management/operator`,
        component: React.lazy(() => import('views/app-views/management/operatorManagement/')),
    },
    {
        key: 'management.operator.detail',
        path: `${APP_PREFIX_PATH}/management/operator/detail/:operatorId`,
        component: React.lazy(() => import('views/app-views/management/operatorManagement/operators/OperatorDetail')),
    },
    {
        key: 'management.operator.edit',
        path: `${APP_PREFIX_PATH}/management/operator/edit/:operatorId`,
        component: React.lazy(() => import('views/app-views/management/operatorManagement/operators/OperatorEdit')),
    },
    {
        key: 'management.operator.add',
        path: `${APP_PREFIX_PATH}/management/operator/add`,
        component: React.lazy(() => import('views/app-views/management/operatorManagement/operators/OperatorAdd')),
    },
    {
        key: 'management.user',
        path: `${APP_PREFIX_PATH}/management/user`,
        component: React.lazy(() => import('views/app-views/management/userManagement/')),
    },
    {
        key: 'management.user.detail',
        path: `${APP_PREFIX_PATH}/management/user/detail/:userId`,
        component: React.lazy(() => import('views/app-views/management/userManagement/users/UserDetail')),
    },
    {
        key: 'management.user.edit',
        path: `${APP_PREFIX_PATH}/management/user/edit/:userId`,
        component: React.lazy(() => import('views/app-views/management/userManagement/users/UserEdit')),
    },
    {
        key: 'management.user.add',
        path: `${APP_PREFIX_PATH}/management/user/add`,
        component: React.lazy(() => import('views/app-views/management/userManagement/users/UserAdd')),
    },
    {
        key: 'management.tariff',
        path: `${APP_PREFIX_PATH}/management/tariff`,
        component: React.lazy(() => import('views/app-views/management/tariffManagement/')),
    },
    {
        key: 'management.tariff.detail',
        path: `${APP_PREFIX_PATH}/management/tariff/detail/:tariffId`,
        component: React.lazy(() => import('views/app-views/management/tariffManagement/tariffs/TariffDetail')),
    },
    {
        key: 'management.tariff.edit',
        path: `${APP_PREFIX_PATH}/management/tariff/edit/:tariffId`,
        component: React.lazy(() => import('views/app-views/management/tariffManagement/tariffs/TariffEdit')),
    },
    {
        key: 'management.tariff.add',
        path: `${APP_PREFIX_PATH}/management/tariff/add/`,
        component: React.lazy(() => import('views/app-views/management/tariffManagement/tariffs/TariffAdd')),
    },
    {
        key: 'management.booking',
        path: `${APP_PREFIX_PATH}/management/booking`,
        component: React.lazy(() => import('views/app-views/management/bookingManagement/bookings/BookingList')),
    },
    {
        key: 'management.booking.add',
        path: `${APP_PREFIX_PATH}/management/booking/add/`,
        component: React.lazy(() => import('views/app-views/management/bookingManagement/bookings/BookingAdd')),
    },
    {
        key: 'management.firmware.upload',
        path: `${APP_PREFIX_PATH}/management/firmware/upload`,
        component: React.lazy(() => import('views/app-views/management/firmwareManagement/firmware/FirmwareUpload')),
    },
    {
        key: 'management.firmware.update',
        path: `${APP_PREFIX_PATH}/management/firmware/update/`,
        component: React.lazy(() => import('views/app-views/management/firmwareManagement/firmware/FirmwareUpdate')),
    },
    {
        key: 'management.smartCharging',
        path: `${APP_PREFIX_PATH}/management/smartCharging`,
        component: React.lazy(() => import('views/app-views/management/smartCharging/chargingGroup/ChargingGroupList')),
    },
    {
        key: 'management.smartCharging.add',
        path: `${APP_PREFIX_PATH}/management/smartCharging/add`,
        component: React.lazy(() => import('views/app-views/management/smartCharging/chargingGroup/ChargingGroupAdd')),
    },
    {
        key: 'management.smartCharging.detail',
        path: `${APP_PREFIX_PATH}/management/smartCharging/detail/:id`,
        component: React.lazy(() => import('views/app-views/management/smartCharging/chargingGroup/ChargingGroupDetail')),
    },
    {
        key: 'management.smartCharging.edit',
        path: `${APP_PREFIX_PATH}/management/smartCharging/edit/:id`,
        component: React.lazy(() => import('views/app-views/management/smartCharging/chargingGroup/ChargingGroupEdit')),
    },
    {
        key: 'logs.auditlogs',
        path: `${APP_PREFIX_PATH}/logs/auditlogs`,
        component: React.lazy(() => import('views/app-views/logs/auditLogs')),
    },
    {
        key: 'logs.diagnostic',
        path: `${APP_PREFIX_PATH}/logs/diagnostic`,
        component: React.lazy(() => import('views/app-views/logs/diagnostic')),
    },
    {
        key: 'logs.ocpp',
        path: `${APP_PREFIX_PATH}/logs/ocpp`,
        component: React.lazy(() => import('views/app-views/logs/ocpp')),
    },
    {
        key: 'logs.maintenancelogs',
        path: `${APP_PREFIX_PATH}/logs/maintenancelogs`,
        component: React.lazy(() => import('views/app-views/logs/maintenanceLogs')),
    },
]
