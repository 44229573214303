import fetch from 'auth/FetchInterceptor'

const BookingService = {}

BookingService.getBookingList = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/booking',
        method: 'get',
        params
        //  {
        //     chargePointId:"VestelEVC1",
        //     connectorId:1,
        //     startDate:"2023-10-25T00:00:00",
        //     endDate:"2023-12-31T23:59:59"
        // }
    })
}

BookingService.addBooking = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/booking',
        method: 'post',
        data: data,
    })
}

BookingService.updateBooking = function(data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/booking',
        method: 'put',
        data: data,
    })
}

BookingService.deleteBooking = function(params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/booking',
        method: 'delete',
        data: params,
    })
}

export default BookingService
