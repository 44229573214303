import fetch from 'auth/FetchInterceptor'

const DashboardService = {}

DashboardService.getAnalyticsData = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/analytics',
        method: 'get',
        params
    })
}

export default DashboardService
